<template>
  <div>
    <!-- 测量记录 -->
    <common-vue :importUrl="importUrl" :listUrl="listUrl" />
  </div>
</template>
<script>
import commonVue from "./components/index.vue";
export default {
  name: "measurementRecord",
  components: { commonVue },
  data() {
    return {
      importUrl: "file/manage/record/import", // 上传资料
      listUrl: "file/manage/record/name" // 列表查询
    };
  }
};
</script>
<style lang="scss" scoped></style>
